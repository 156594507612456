<template>
  <div class="system-infomation">
    <div class="title-mark">企业信息</div>
    <div class="corp-info">
      <a-descriptions :column="1">
        <a-descriptions-item label="企业简称">{{corpInfo.name}}</a-descriptions-item>
        <a-descriptions-item label="企业全称">{{corpInfo.fullName}}</a-descriptions-item>
        <a-descriptions-item label="企业ID">{{corpInfo.workWechatCorpId}}</a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>

<script>
import corpSettingApi from "@/service/api/corpSetting";
import { Descriptions } from "ant-design-vue";

export default {
  name: "SystemInformation",

  components: {
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
  },

  data() {
    return {
      corpInfo: {},
    };
  },

  computed: {},

  mounted() {},

  created() {
    this.getCorpBaseInfo();
  },
  methods: {
    async getCorpBaseInfo() {
      this.corpInfo = await corpSettingApi.getCorpWorkWechatBaseInfo();
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.ant-descriptions-item) > span {
  justify-content: flex-end;
}
:deep(.ant-descriptions-item-label) {
  margin-right: 8px;
  min-width: 70px;
  color: @gray-dark;
}
</style>
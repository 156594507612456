import { apiClient as client } from '@/shared/service/api-client';

export default {
  // 客户联系配置
  async getCorpWorkWechatBaseInfo(params) {
    return await client.get('/corpSetting/getCorpWorkWechatBaseInfo', { params: params });
  },
  async saveAppAgentId(params) {
    return await client.post('/corpSetting/saveAppAgentId', params);
  },
  async saveCorpWechatInfo(params) {
    return await client.post('/corpSetting/saveCorpWechatInfo', params);
  },

  // 网校信息获取
  async getESRelationConfig() {
    return await client.get('/corpSetting/getESRelationConfig');
  },

  // 获取全局配置
  async getConfigurations() {
    return await client.get('/corpSetting/getConfigurations');
  },
  async getBindWechatAccountStatus() {
    return await client.get('/corpSetting/getBindWechatAccountStatus');
  },
}
